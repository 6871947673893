var render, staticRenderFns
import script from "./waybill.vue?vue&type=script&lang=js&"
export * from "./waybill.vue?vue&type=script&lang=js&"
import style0 from "./waybill.vue?vue&type=style&index=0&id=f4a97aa2&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4a97aa2",
  null
  
)

export default component.exports